var Highcharts = require('highcharts/highcharts-gantt');

import { IMilestone } from './interfaces';

export class MilestoneGanttChart {

    chart: any;

    constructor() { }

    initialize(milestones: IMilestone[]): void {

        //console.log(milestones);

        let minDate: number = null;
        let maxDate: number = null;
        let startSpacerData: MilestoneDisplayData;
        let endSpacerData: MilestoneDisplayData;

        let chartHash: Map<string, MilestoneDisplayData> = new Map<string, MilestoneDisplayData>();

        let groupPrefix = "group-";

        for (let i = 0; i < milestones.length; ++i) {
            let milestone = milestones[i];

            let categoryData: MilestoneDisplayData = chartHash.get(groupPrefix + milestone.Category.toLowerCase());
            if (categoryData == null) {
                categoryData = new MilestoneDisplayData(milestone.Category, "group", groupPrefix + milestone.Category.toLowerCase(), null, true);
                categoryData.setColorByStatus(milestone.Status);

                //the first category
                if (chartHash.size == 0) {
                    startSpacerData = new MilestoneDisplayData(milestone.Category, "spacer", null, null, null);
                    endSpacerData = new MilestoneDisplayData(milestone.Category, "spacer", null, null, null);
                }
            }
            categoryData.checkDates(this.getDate(milestone.StartDate), this.getDate(milestone.DueDate), this.getDate(milestone.ActualStartDate), (milestone.Status == "Completed") ? this.getDate(milestone.DeliveredDate) : (milestone.Status == "In Progress") ? this.today() : null);
            chartHash.set(groupPrefix + milestone.Category.toLowerCase(), categoryData);

            //to set the bounds of the x-axis
            if (minDate == null || (categoryData.start != null && categoryData.start < minDate)) minDate = categoryData.start;
            if (maxDate == null || (categoryData.end != null && categoryData.end > maxDate)) maxDate = categoryData.end;

            let actualWork: MilestoneDisplayData = this.createActualWork(milestone, groupPrefix + milestone.Category.toLowerCase(), this.today());
            let plannedWork: MilestoneDisplayData = this.createPlannedWork(milestone, groupPrefix + milestone.Category.toLowerCase());
            let actualMilestone: MilestoneDisplayData = this.createActualMilestone(milestone, groupPrefix + milestone.Category.toLowerCase());
            let plannedMilestone: MilestoneDisplayData = this.createPlannedMilestone(milestone, groupPrefix + milestone.Category.toLowerCase());

            //order matters
            if (actualWork != null) { chartHash.set("actualWork-" + milestone.Title.toLowerCase(), actualWork); }
            if (plannedWork != null) { chartHash.set("plannedWork-" + milestone.Title.toLowerCase(), plannedWork); }
            if (actualMilestone != null) { chartHash.set("actualDeliv-" + milestone.Title.toLowerCase(), actualMilestone); }
            if (plannedMilestone != null) { chartHash.set("plannedDeliv-" + milestone.Title.toLowerCase(), plannedMilestone); }
        }

        //push hidden data to end and beginning last so scroll sets to far left and not far right
        endSpacerData.start = maxDate + (30 * 24 * 60 * 60 * 1000); //30 days after latest event
        endSpacerData.end = endSpacerData.start;
        chartHash.set("end-spacer", endSpacerData);
        maxDate = endSpacerData.end;

        startSpacerData.start = minDate - (30 * 24 * 60 * 60 * 1000); //30 days before earliest event
        startSpacerData.end = startSpacerData.start;
        chartHash.set("start-spacer", startSpacerData);
        minDate = startSpacerData.start;

        //console.log(Array.from(chartHash.values()));

        // THE CHART
        Highcharts.ganttChart('gantt-chart', {
            credits: { enabled: false },
            chart: {
                styledMode: false,
            },
            xAxis: {
                min: minDate,
                max: maxDate,
                tickInterval: (24 * 3600 * 1000 * 30), //stay at month view
                currentDateIndicator: {
                    width: 3,
                    dashStyle: 'solid',
                    color: 'rgba(39,73,110)',
                    label: {
                        format: ''
                    }
                }
            },
            yAxis: {
                uniqueNames: true,
                labels: {
                    style: {
                        useHTML: true,
                        wordwrap: 'break-word',
                        format: '<div style="width: 200px;">{text}</div>'
                        //textOverflow: 'ellipsis'
                    }
                }
            },
            tooltip: {
                headerFormat: '',
                dateTimeLabelFormats: {
                    hour: "%A, %b %e",
                }
            },
            legend: { enabled: false },
            scrollbar: {
                enabled: true,
            },
            rangeSelector: {
                enabled: true,
                inputEnabled: false,
                selected: 3,
                buttonPosition: {
                    align: 'right'
                },
                buttons: [{
                    type: 'month',
                    count: 3,
                    text: '3m',
                    events: { click: (event) => { this.setZoom(7862400000); } }
                }, {
                    type: 'month',
                    count: 6,
                    text: '6m',
                    events: { click: (event) => { this.setZoom(15897600000); } }
                }, {
                    type: 'year',
                    count: 1,
                    text: '1y',
                    events: { click: (event) => { this.setZoom(31536000000); } }
                }, {
                    type: 'all',
                    text: 'All',
                    events: { click: (event) => { this.setZoom(-1); } }
                }]
            },

            series: [{ data: Array.from(chartHash.values()) }]

        }, (chart) => this.chart = chart);
    }

    today(): Date {
        let today: Date = new Date();

        // Set to 00:00:00:000 today
        today.setHours(24);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);
        return today;
    }

    createActualWork(milestone: IMilestone, parent: string, today: Date): MilestoneDisplayData {

        let actual: MilestoneDisplayData = new MilestoneDisplayData(milestone.SortOrder.toString(), "actual", null, parent, null);
        actual.checkDates(null, null, this.getDate(milestone.ActualStartDate), (milestone.Status == "Completed") ? this.getDate(milestone.DeliveredDate) : (milestone.Status == "In Progress") ? today : null);
        actual.setColorByStatus("Completed"); //or in progress - doesn't matter - just need the non-planning color
        actual.milestone = false;

        if (actual.start != null && actual.end != null && actual.start != actual.end) return actual;
        else return null;
    }

    createActualMilestone(milestone: IMilestone, parent: string): MilestoneDisplayData {

        if (milestone.DeliveredDate != null && milestone.DeliveredDate != "") {
            let actual: MilestoneDisplayData = new MilestoneDisplayData(milestone.SortOrder.toString(), "actual", null, parent, null);
            actual.setDates(this.getDate(milestone.DeliveredDate).getTime(), this.getDate(milestone.DeliveredDate).getTime());
            actual.setColorByStatus("Completed"); //or in progress - doesn't matter - just need the non-planning color
            actual.milestone = true;
            return actual;
        }
        else return null;
    }

    createPlannedWork(milestone: IMilestone, parent: string): MilestoneDisplayData {
        let planned: MilestoneDisplayData = new MilestoneDisplayData(milestone.SortOrder.toString(), "planned", null, parent, null);
        planned.checkDates(this.getDate(milestone.StartDate), this.getDate(milestone.DueDate), null, null);
        planned.setColorByStatus("Not Started");
        planned.milestone = false;

        if (planned.start != null && planned.end != null && planned.start != planned.end) return planned;
        else return null;
    }

    createPlannedMilestone(milestone: IMilestone, parent: string): MilestoneDisplayData {

        if (milestone.DueDate != null && milestone.DueDate != "") {

            let planned: MilestoneDisplayData = new MilestoneDisplayData(milestone.SortOrder.toString(), "planned", null, parent, null);
            planned.setDates(this.getDate(milestone.DueDate).getTime(), this.getDate(milestone.DueDate).getTime());            
            planned.setColorByStatus("Not Started"); 
            planned.milestone = true;
            return planned;
        }
        else return null;
    }

    setZoom(delta: number): void {
        let start: number = this.chart.xAxis[0].dataMin;
        let end: number = (delta == -1) ? this.chart.xAxis[0].dataMax : start + delta

        this.chart.xAxis[0].setExtremes(start, end);
        this.chart.redraw();
    }

    getDate(dateString: string): Date {
        if (dateString == null || dateString == "") return null;

        let tempDate: Date = new Date(dateString);
        tempDate.setHours(12);
        tempDate.setMinutes(0);
        tempDate.setSeconds(0);
        tempDate.setMilliseconds(0);

        return tempDate;
    }

}

class MilestoneDisplayData {

    name: string = null;
    id: string = null;
    parent: string = null;
    start: number = null;
    end: number = null;
    color: string = null;
    type: string = null;
    milestone: boolean = null;
    collapsed: boolean = null;
    pointWidth: number = 20; //default

    constructor(name: string, type: string, id: string, parent: string, collapsed: boolean) {
        this.name = name;
        this.type = type;
        this.id = id;
        this.parent = parent;
        this.collapsed = collapsed;
    }

    //once a milestone is made active, don't let it revert to scheduled
    setColorByStatus(status: string) {
        if (status == 'Completed' || status == 'In Progress') this.color = 'rgba(39,73,110)';
        else if (this.color == null) {
            this.color = 'rgba(93, 173, 224)';
            //this.color = 'rgba(1,144,158)';
            this.pointWidth = 10;
        }
    }

    setDates(start: number, end: number): void {
        this.start = start;
        this.end = end;
    }

    checkDates(plannedStart: Date, plannedEnd: Date, actualStart: Date, actualEnd: Date): void {
        this.checkDateBounds(plannedStart);
        this.checkDateBounds(plannedEnd);
        this.checkDateBounds(actualStart);
        this.checkDateBounds(actualEnd);
    }

    checkDateBounds(date: Date): void {
        if (date != null) {
            if (this.start == null) this.start = date.getTime();
            else if (date.getTime() < this.start) this.start = date.getTime();

            if (this.end == null) this.end = date.getTime();
            else if (date.getTime() > this.end) this.end = date.getTime();
        }
    }
}
