

export class CapitationPage {

    constructor() {
        var modal = $('#view-document-modal');
        var module1 = $("#module-1-link");
        var module2 = $("#module-2-link");
        var module3 = $("#module-3-link");

        module1.click(function () {
            modal.find('#filename').html("Module 1 Participant Payment Schedule_final.pdf");
            modal.find('#document-iframe').attr('src', '/docs/Module 1 Participant Payment Schedule_final.pdf');
            modal.modal('show');
        });

        module2.click(function () {
            modal.find('#filename').html("Module 2 Participant Payment Schedule_final.pdf");
            modal.find('#document-iframe').attr('src', '/docs/Module 2 Participant Payment Schedule_final_v2.0.pdf');
            modal.modal('show');
        });

        module3.click(function () {
            modal.find('#filename').html("Module 3 Participant Payment Schedule_final.pdf");
            modal.find('#document-iframe').attr('src', '/docs/Module 3 Participant Payment Schedule_final_v1.0.pdf');
            modal.modal('show');
        })

    }

    
}


