import { plainToClass } from "class-transformer";
import { UserDirectoryEntity } from "./directory.objects";

export class DirectoryPage {

    users: UserDirectoryEntity[];
    dataTable: any;

    constructor(data: any) {
        $(() => {

            this.users = plainToClass(UserDirectoryEntity, <UserDirectoryEntity[]>data);
            this.users = this.users.map((u: UserDirectoryEntity) => {
                u.initialize();
                return u;
            });

            this.initializeTable();
            this.setUsersFoundLabel(this.users.length);
        });
    }
    //AM 4/28/22: Commented this out since there is no filter-dropdown-8' in cshtml pg. Can delete once 
    //            confirmed not needed.
    //initializeCommittes(committees: any) {
    //    $('#filter-dropdown-8').empty();

    //   committees.forEach((element: any) => {
    //        $('#filter-dropdown-8').append(new Option(element, element, false, true));
    //    });
    //
	//}

    initializeTable(): void {

        this.dataTable = $('#user-directory-table').DataTable({
            //"dom": '<"top-controls"<"message-section">f>rtip',
            "dom": '<"top-controls"<"reset-section">Bf>rtip',
            autoWidth: false,
            info: false,
            paging: false,
            search: true,
            scrollX: false,
            //scrollY: '70vh',
            orderCellsTop: true,
            language:
            {
                search: "Filter",
                buttons: {
                    copyTitle: 'Lab Assays Data Copied',
                    copyKeys: 'Use your keyboard or menu to select the copy command'
                }
            },
            columns: [
                { data: "DisplayName", className: "text-left font-size13 pl-4" }, //0
                { data: "Email", className: "text-left font-size13", width: '11%' }, //1
                { data: "PhoneNumber", className: "text-left font-size13", width: '11%' }, //2
                { data: "ProjectRole", className: "text-left font-size13", width: '11%' }, //3
                { data: "Expertise", className: "text-left font-size13", width: '11%' },
                { data: "CoreCohort", className: "text-left font-size13 pl-3", width: '11%' }, //4
                { data: "Organization", className: "text-left font-size13 pl-3", width: '11%' }, //5
                { data: "Committees", className: "text-left font-size13 pl-3", visible: false }, //6
                { data: "WGs", className: "text-left font-size13 pl-3", visible: false }, //6
            ],
            columnDefs: [{ //to sort by last name first
                targets: 0,
                data: "DisplayName",
                render: function (data, type, full, meta) {
                    if (type == 'sort') {
                        data = full.SortName;
                    }
                    return data;
                }
            }],
            buttons: [
                //{
                //    extend: 'copy',
                //    text: '<i class="fas fa-copy"></i>',
                //    titleAttr: 'Copy',
                //    charset: 'utf-8',
                //    exportOptions: {
                //        columns: [0, 1, 2, 3, 4]
                //    },
                //    title: ''
                //}
                , {
                    extend: 'csv',
                    text: '<i class="fas fa-file-csv"></i>',
                    titleAttr: 'CSV',
                    charset: 'utf-8',
                    exportOptions: {
                        columns: [0, 1, 2, 3, 4, 5, 6, 7]
                    }
                }
                , {
                    text: '<i class="fas fa-envelope"></i>',
                    titleAttr: 'Create Email List',
                    charset: 'utf-8',
                    action: (e, dt, node, config) => {
                        this.copyVisibleEmailToClipboard();
                    }
                },
            ],
            data: this.users,
            //order: [[4, 'desc'], [7, 'desc']],
            order: [[0, 'asc']],
            initComplete: (settings, json) => {
                $("#user-directory-table").wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
            },
        });

        let resetSectionDiv: string =
            "<div>" +
            "<button type=\"button\" class=\"btn btn-sm btn-outline-secondary font-size12\" onclick=\"page.resetFilters()\">Reset Filters</button>" +
            "</div>"
            ;
        $(".reset-section").empty().html(resetSectionDiv);
        $(".reset-section").css('display', 'inline-block');
        $(".reset-section").css('float', 'left');
        $(".reset-section").css('margin-top', '5px');

        $('#user-directory-table').on('search.dt', (e, settings) => {
            this.setUsersFoundLabel(this.dataTable.rows({ search: 'applied' }).count());
        });

        this.initializeFilters();
    }

    initializeFilters(): void {
        
        for (let index = 5; index <= 8; ++index) { //filter on organization and affiliation
            let selectId: string = '#filter-dropdown-' + index;
            let filterIndex: number = index;
            
                let uniqueValues: string[] = [];
                let delimiter: string = ",";


                let dropdownSourceIndex: number = index;

                this.dataTable.column(dropdownSourceIndex).data().unique().map((data: string) => {
                    let parts: string[] = [];
                    if (delimiter != "") { parts = data.split(delimiter); }
                    else { parts[0] = data; }

                    parts.map((part: string) => {
                        part = part.trim();
                        if (part.length > 0 && !uniqueValues.includes(part)) {
                            uniqueValues.push(part);
                        }
                    });
                });

                uniqueValues = uniqueValues.sort((a: string, b: string) => {
                    return a.toLowerCase().localeCompare(b.toLowerCase());
                });

                uniqueValues.forEach((element: any) => {
                    $(selectId).append(new Option(element, element, false, true));
                });

                
            
            
            $(selectId).on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {
                let values: string[] = <string[]>($(selectId).val());
                //console.log("changed", values);

                let search: string = "";
                
                if (values.length > 0) {
                    search = "(\\b" + values.join("\\b)|(\\b") + "\\b)";
                }
                else { search = "NOTHING-MATCHED-1234567890"; }
                console.log("search1 ", search);
                search = search.split("(").join("").split(")").join("");
                console.log("search2 ", search);
                this.dataTable.column(filterIndex)
                    .search(search, true, false)
                    .draw();
            });
            

            $(selectId).selectpicker({
                dropupAuto: false,
                selectedTextFormat: "count",

                countSelectedText: (numSelected, numTotal) => {
                    if (numSelected == numTotal) {
                        return "All";
                    }
                    else {
                        return numSelected + " Selected";
                    }
                }
            });

            $(selectId).selectpicker("refresh");


            

        }        
    }

    resetFilters(): void {
        let filterIndex: number;
        for (let index = 8; index >= 5; --index) { //skip details and title column

            $('#filter-dropdown-' + index).selectpicker('selectAll');
            $('#filter-dropdown-' + index).selectpicker("refresh");

            filterIndex = index;
            this.dataTable.column(filterIndex).search('').draw();
        }
        this.dataTable.search('').draw();
    }

    setUsersFoundLabel(count: number): void {

        switch (count) {
            case 0: $('#users-found-count').text("Nobody Found"); break;
            case 1: $('#users-found-count').text("1 Person Found"); break;
            default: $('#users-found-count').text(count + " People Found");
        }
    }

    copyVisibleEmailToClipboard(): void {
        let visibleUsers: UserDirectoryEntity[] = this.dataTable.rows({ search: 'applied' }).data().toArray();
        let emailList: string[] = visibleUsers.map((u: UserDirectoryEntity) => {
            return u.Email;
        });

        let emailListString: string = emailList.join("; ");

        navigator.clipboard.writeText(emailListString)
            .then(() => {
                $('#emailListValue').text(emailListString);
                $('#directory-email-modal').modal('show');
            },
            () => {
                alert("There was an error copying to the clipboard. Please try again.");
            });
    }
}