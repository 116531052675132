import { DocumentTree } from '../../shared/components/document-tree/document-tree.objects';

export class BrandingPage {

    constructor(contentBrand: any, contentAssets: any, contentLogo: any, contentVisualElements: any, contentIcons: any, contentTabling: any, contentFlyers: any, contentBanners: any, contentBackground: any, contentPosterTemplate: any, contentPowerpoint: any, contentWord: any, contentLabels: any, contentSign: any, contentPostcard: any, contentVideos: any) {
        $(() => {

            //console.log(contentBrand, contentAssets, contentLogo);

            new DocumentTree(contentBrand, "guideline-tree");
            new DocumentTree(contentAssets, "asset-tree");
            new DocumentTree(contentLogo, "logo-tree");
            new DocumentTree(contentVisualElements, "visual-element-tree");
            new DocumentTree(contentIcons, "icons-tree");
            new DocumentTree(contentTabling, "tabling-exhibit-tree");
            new DocumentTree(contentFlyers, "flyers-tree");
            new DocumentTree(contentBanners, "digital-banners-tree");
            new DocumentTree(contentBackground, "virtual-backgrounds-tree");
            new DocumentTree(contentPosterTemplate, "technical-poster-tree");
            new DocumentTree(contentPowerpoint, "powerpoint-tree");
            new DocumentTree(contentWord, "word-tree");
            new DocumentTree(contentLabels, "labels-tree");
            new DocumentTree(contentSign, "sign-tree");
            new DocumentTree(contentPostcard, "postcard-tree");
            new DocumentTree(contentVideos, "videos-tree");

        });
    }

}