
export class UserDirectoryEntity {

    FirstName: string;
    LastName: string;
    ProjectRole: string;
    Email: string;
    Organization: string;
    CoreCohort: string;
    Country: string;
    Degrees: string;
    PhoneNumber: string;

    DisplayName: string;
    SortName: string;
    Membership: string;
    HiddenFilter: string = "";
    Committees: string;
    Expertise: string;

    WGs: string;

    constructor() { }

    initialize(): void {
        if (this.Degrees == undefined) this.Degrees = "";
        if (this.Expertise == undefined) this.Expertise = "";

        this.DisplayName = this.FirstName + " " + this.LastName;
        if (this.Degrees.length > 0) { this.DisplayName += (", " + this.Degrees); }
        this.SortName = this.LastName + " " + this.FirstName;

        //this.DisplayName = "<span style='white-space: nowrap'>" + this.DisplayName + "</span>";

        if (this.PhoneNumber.length > 0) {
            this.PhoneNumber = "<span style='white-space: nowrap'>" + this.PhoneNumber + "</span>";
        }

        if (this.Committees == undefined) this.Committees = "";
        if (this.WGs == undefined) this.WGs = "";
    }
}